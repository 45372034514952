(function ($) {
  "use strict";
  $(document).ready(function () {

    //Menu Mobile
    $(".burger").on("click", function (e) {
      e.preventDefault();
      if (!$(this).hasClass("open")) {
        openMenu();
      } else {
        closeMenu();
      }
    });

    function openMenu() {
      $(".menu-bg").addClass("animate");
      $(".burger").addClass("open");
      $(".x, .z").addClass("collapse-icon");
      $(".menu-navigation").addClass("animate");
      $("body").css({
        overflow: "hidden",
        height: "100vh"
      });
      // var header = $('.header').outerHeight();
      // console.log(header)
      // $('.menu-navigation').css({
      //   'top': header
      // });
      setTimeout(function () {
        $(".y").hide();
        $(".x").addClass("rotate30");
        $(".z").addClass("rotate150");
      }, 70);
      setTimeout(function () {
        $(".x").addClass("rotate45");
        $(".z").addClass("rotate135");
      }, 120);
    };

    function closeMenu() {
      $(".menu-navigation").removeClass("animate");
      $("body").css({
        overflow: "",
        height: ""
      });
      // $('.menu-navigation').css({
      //   'top': ''
      // });

      setTimeout(function () {
        $(".burger").removeClass("open");
        $(".x")
          .removeClass("rotate45")
          .addClass("rotate30");
        $(".z")
          .removeClass("rotate135")
          .addClass("rotate150");
        $(".menu-bg").removeClass("animate");

        setTimeout(function () {
          $(".x").removeClass("rotate30");
          $(".z").removeClass("rotate150");
        }, 50);
        setTimeout(function () {
          $(".y").show();
          $(".x, .z").removeClass("collapse-icon");
        }, 70);
      }, 100);
    };

    //Convert img to svg
    $('img.svg').each(function () {
      var $img = $(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');

      $.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, else we gonna set it if we can.
        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
          $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

      }, 'xml');
    });

    //Header menu padding
    function headerMenu() {
      if($(window).width() > 1199) {
        var menuSpace = Math.floor($('.main-menu > li ').length / 2);
        $( ".main-menu > li:eq(" + menuSpace + ")" ).addClass( "menu-spaces" );
        $('.dropdown-toggle').attr('data-toggle', '');
      } else {
        $('.dropdown-toggle').attr('data-toggle', 'dropdown');
        $( ".main-menu > li:eq(" + menuSpace + ")" ).removeClass( "menu-spaces" );
      }
    };
    headerMenu();

    $(window).resize(function () {
      headerMenu();
    });

    // slick slider
    $('.hero-slider').slick({
      dots: true,
      arrows: true,
      appendArrows:$('.slider-controls'),
      appendDots:$('.slider-controls'),
      autoplay: true,
    });

    // slick slider
    $('.slider-products').slick({
      dots: true,
      arrows: true,
      appendArrows:$('.slider-products-controls'),
      appendDots:$('.slider-products-controls'),
      autoplay: true,
    });

    // modal foto
    $('.card-foto-detail').on("click", function (e) {
      e.preventDefault();
      var dataId = $(this).attr('data-id');
      $('.slider-modal').slick({
        initialSlide: parseInt(dataId),
        slidesToScroll: 1,
        dots: false,
        prevArrow: "<a class='slick-prev'><img src='assets/images/icons/icon-previous.svg' alt='' /></a>",
        nextArrow: "<a class='slick-next'><img src='assets/images/icons/icon-next.svg' alt='' /></a>",
      });
    });
    $('.modal').on('shown.bs.modal', function (e) {
      $('.slider-modal').slick('setPosition')
    });
    $(".modal").on('hidden.bs.modal', function(){
      $('.slider-modal').slick('unslick')
    });

    // faq
    $(".panel-faq .content .title").on('click', function (event) {
      event.preventDefault();
      $(this).parent().toggleClass('expand');
      $(this).next().slideToggle('fast');
    });

    //mobile search
    $('.search-mobile i').on('click', function (event) {
      event.preventDefault();
      $('.form-search').toggleClass('show')
    });
    $(document).on("click", function(event){
      var $trigger = $(".search-mobile");
      if($trigger !== event.target && !$trigger.has(event.target).length){
        $('.form-search').removeClass('show')
      }            
  });

    //distributor
    function distributorFilter() {
      var filter = $(".distributor-filter .btn-filter.active").attr('data-list');
      $('#'+filter+'').fadeIn(500);
    }
    distributorFilter();

    $(".distributor-filter .btn-filter").on('click', function (event) {
      event.preventDefault();
      var filter = $(this).attr('data-list');
      $(".distributor-filter .btn-filter").removeClass('active');
      $(this).addClass('active');
      $('.list-distributor > div').hide();
      $('#'+filter+'').fadeIn(500);
    });

    //Toko Offline
    function offlineStoreFilter() {
      var filter = $(".offline-store-filter .btn-filter.active").attr('data-store');
      $('.outlet-modern > div').hide();
      $('#'+filter+'').fadeIn(500);
    }
    offlineStoreFilter();

    $(".offline-store-filter .btn-filter").on('click', function (event) {
      event.preventDefault();
      var filter = $(this).attr('data-store');
      $(".offline-store-filter .btn-filter").removeClass('active');
      $(this).addClass('active');
      $('.outlet-modern > div').hide();
      $('#'+filter+'').fadeIn(500);
    });

    //filter product list 
    $('.filter-trigger').on('click', function (event) {
      event.preventDefault();
      $('.filter-sidebar').css( 'transform', 'translateY(0)' );
      $('body, html').css('overflow', 'hidden')
    });
    
    $('.filter-actions button').on('click', function (event) {
      event.preventDefault();
      $('.filter-sidebar').css( 'transform', '' );
      $('body, html').css('overflow', '')
    });
    
  });
})(jQuery);